import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Zinshaus = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Die wichtigsten Infos zum Thema Zinshaus" showCalc={false} />
            <Article>
                <p>
                    Du hast den Begriff Zinshaus bestimmt schon gehört, vielleicht fragst du dich trotzdem, was
                    eigentlich ein Zinshaus von anderen Gebäuden unterscheidet. Worauf muss man bei der Investition in
                    Zinshäuser achten? Und warum verkaufen so viele Menschen ihre Zinshäuser, obwohl es immer noch als
                    beliebtes Anlageobjekt gilt? In diesem Artikel bekommst du einen Überblick über das Wichtigste, das
                    du zum Thema Zinshaus wissen solltest.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist eigentlich ein Zinshaus?</h2>
                <p>
                    Der Begriff Zinshaus ist vor allem in Österreich gebräuchlich, um Gebäude, die vor 1945 errichtet
                    wurden und vermietet werden, zu beschreiben. Es handelt sich dabei vor allem um die beliebten Wiener
                    Altbauwohnungen, aber Zinshäuser finden sich nicht nur in Wien, sondern auch in Graz, Linz oder
                    Salzburg. Ein Zinshaus ist also ein Miethaus, das aus mehreren Wohneinheiten besteht und gegen ein
                    monatliches Entgelt, also den Zins, längerfristig vermietet wird. Mittlerweile wird der Begriff
                    Zinshaus aber auch auf andere Mietshäuser angewandt, die nach 1945 errichtet wurden, nur
                    Genossenschaftswohnungen und geförderte Wohnungen werden nicht dazugezählt.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Warum sind sie ein beliebtes Anlageobjekt?</h2>
                <p>
                    Zinshäuser sind ein beliebtes{" "}
                    <Link
                        to="/artikel/rechte-und-pflichten-als-zukunftige-hauseigentumerin/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Anlageobjekt
                    </Link>
                    , weil Altbauwohnungen bei Mieter*innen beliebt sind und sich so immer Mieter*innen finden lassen
                    werden. Die schönen Fassaden, der Charme der Innenräume und der hohen Decken verleihen jedem
                    klassischen Zinshaus einen besonderen Reiz. Die historischen Gründerzeithäuser sind wichtig für das
                    Stadtbild und die Lebensqualität darin ist meistens hoch.
                </p>
                <hr />

                <h2>Lohnt sich die Investition?</h2>
                <p>
                    Die Investition in Zinshäuser kann sich lohnen, ist jedoch natürlich abhängig vom Wert des
                    Zinshauses. Steht es in einer guten Lage und ist es in einem guten Zustand, kann man mit sehr hohen
                    Renditen rechnen. Die Nachfrage ist im Steigen begriffen und somit steigt auch der Wert der Häuser
                    stetig. Doch vor dem Kauf muss dieser Wert gründlich überprüft werden. Da Zinshäuser über hundert
                    Jahre alt sein können, ist die Instandhaltung aufwendiger, als bei Neubauten und eine schlecht
                    erhaltene Bausubstanz kann zu hohen Erhaltungskosten führen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist wirklich eine „gute Lage“?</h2>
                <p>
                    Eine beliebte Lage hängt nicht nur vom Stadtteil oder Bezirk ab, sondern auch davon, ob das Haus an
                    einer lauten stark befahrenen Straße liegt, oder in einer ruhigen Gasse. Die Anbindung an den
                    öffentlichen Verkehr, Geschäfte in der Umgebung und Bildungseinrichtungen spielen ebenfalls eine
                    große Rolle bei der Bewertung der Lage. Wenn es in der Nähe Grünflächen oder ruhige Gassen gibt,
                    steigt der Wert des Zinshauses. In Wien kann man vor allem in den Bezirken innerhalb des Gürtels und
                    im 2., 13., 14., 18., und 19. hohe Erträge erzielen. Spitzenreiter sind dabei der 1., 4., 9. und der
                    19. Bezirk. Aber es gibt auch andere aufstrebende Viertel, wie die Umgebung rund um den Brunnenmarkt
                    im 16. Bezirk, die als gute Lage gelten.
                </p>
                <hr />

                <h2>Was kostet die Sanierung eines Zinshauses?</h2>
                <p>
                    Da Zinshäuser mitunter über hundert Jahre alt sein können, liegt die Frage nahe, wie teuer eine{" "}
                    <Link to="/artikel/baufinanzierung/" target="_blank" rel="noreferrer noopener">
                        Sanierung
                    </Link>{" "}
                    eines solchen Hauses kommen kann. Das hängt ganz vom Zustand des Hauses ab. Wenn man den Zustand der
                    Bausubstanz überprüfen will, sollte man unbedingt einen Profi zu Rate ziehen. Schätzt man diesen
                    nämlich falsch ein, kann man auch die Kosten nicht richtig einschätzen. Dabei werden die Stabilität
                    des Fundaments, die Abnutzung von Leitungen und Rohren, die Dachkonstruktion und der Zustand aller
                    Fenster, Türen und Stiegen genau überprüft. Auch die Innenräume der Wohnungen und ihr Zustand werden
                    bewertet. Wenn ein Zinshaus saniert wird, verbessert man die Wohnqualität enorm und steigert somit
                    den Wert der Immobilie. Die Kosten ergeben sich aus dem Zustand des Hauses, der Art und dem Umfang
                    aller Schäden und der Größe des Bereiches, der saniert werden soll. Dabei müssen nicht nur Arbeits-
                    und Materialkosten, sondern auch Nebenkosten, wie Honorare für Expert*innen oder rechtliche Beratung
                    mitbedacht werden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was kostet ein Dachbodenausbau?</h2>
                <p>
                    Es ist äußerst beliebt, ein Zinshaus durch einen Dachbodenausbau aufzuwerten. Die Wertsteigerung ist
                    enorm, allerdings lohnt sich die Investition erst ab einer Größe von ca. 250m², da auch die Kosten
                    dafür sehr hoch sind. Man muss mit mindestens 3.000 Euro pro Quadratmeter Fläche rechnen. Dabei
                    sollte man auch unbedingt jemanden beauftragen, der bereits Erfahrungen mit dem Dachbodenausbau
                    alter Zinshäuser hat.
                </p>
                <hr />

                <h2>Worauf du achten musst, falls dein Zinshaus denkmalgeschützt ist</h2>
                <p>
                    Sehr viele Zinshäuser sind aufgrund ihrer Bedeutung für das Stadtbild denkmalgeschützt.
                    Denkmalgeschützte Gebäude dürfen nur saniert oder baulich verändert werden, wenn das
                    Bundesdenkmalamt eine Bewilligung erteilt. Ob dein Zinshaus unter Denkmalschutz steht, kannst du
                    online nachsehen. Es gibt eine Liste des Bundesdenkmalamtes, die jährlich aktualisiert wird.
                    Außerdem wird der Schutz auch im Grundbuch eingetragen, das vor jedem Kauf geprüft werden sollte.
                    Als Besitzer eines geschützten Zinshauses ist man außerdem dazu verpflichtet, dieses zu erhalten.
                    Das ist der Grund, warum sich viele für den Verkauf entscheiden: Die Erhaltungskosten können sehr
                    hoch sein. Aber es gibt auch zahlreiche Vorteile, wie zum Beispiel steuerliche Erleichterungen oder
                    dass bei denkmalgeschützten Zinshäusern keine Richtwertmiete gilt.
                </p>
                <p>
                    Wenn du all die oben genannten Punkte gut überprüfst und durchdenkst, kannst du entscheiden, ob sich
                    die Investition in ein Zinshaus lohnt, oder nicht. Angebote gibt es momentan viele, aber um eine
                    professionelle Beratung kommst du nicht herum. Für eine erste Orientierung zur Finanzierung des
                    Zinshauses haben wir einen{" "}
                    <Link to="/artikel/wohnkredit-rechner/" target="_blank" rel="noreferrer noopener">
                        Online-Kreditrechner
                    </Link>{" "}
                    entwickelt. Über die Finanzierung des Immobilienkaufs oder auch der Sanierung solltest du dich
                    ebenso gründlich informieren, wie über den Zustand und den tatsächlichen Wert der Immobilie.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"zinshaus"}></BreadcrumbList>
            <ArticleStructuredData page={"zinshaus"} heading={"Die wichtigsten Infos zum Thema Zinshaus"} />
        </Layout>
    );
};

export default Zinshaus;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["header", "footer", "page.artikel.zinshaus"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
